'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import cx from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace globalThis {
  const instgrm: {
    Embeds: {
      process: () => void;
    };
  };
}

interface EmbeddedPostProps {
  open: boolean;
  html?: string;
  api?: string;
  index?: number;
  onClose?: () => void;
  openNewPost?: (index: number) => void;
}

export function EmbeddedPost({
  open,
  api,
  html,
  index,
  onClose,
  openNewPost
}: EmbeddedPostProps) {
  const embedRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [swiped, setSwiped] = useState(false);

  const close = useCallback(() => {
    if (!embedRef.current || swiped) return; // Prevent closing if a swipe occurred
    embedRef.current.innerHTML = '';
    setIsLoaded(false);
    onClose?.();
  }, [embedRef.current, onClose, swiped]);

  const handleSwipe = (direction: string) => {
    setSwiped(true); // Set the swipe flag to true
    setIsLoaded(false);
    if (direction === 'Left') {
      openNewPost?.(index! + 1); // Trigger next post
    } else if (direction === 'Right') {
      openNewPost?.(index! - 1); // Trigger previous post
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiping: () => setSwiped(true),
    onSwipedLeft: () => handleSwipe('Left'),
    onSwipedRight: () => handleSwipe('Right'),
    onSwiped: () => setTimeout(() => setSwiped(false), 300), // Reset swipe after a delay
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  // Listen to Escape key globally to close the modal
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close(); // Trigger close on Escape
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp); // Cleanup
    };
  }, [close]);

  useEffect(() => {
    if (!html || !embedRef.current) return;

    embedRef.current.innerHTML = html;
    if (api === 'Instagram') {
      globalThis.instgrm.Embeds.process();

      const iframe = embedRef.current.querySelector('iframe');
      iframe?.addEventListener('load', () => setIsLoaded(true));
    } else if (api === 'TikTok') {
      const existingScripts = document.querySelectorAll(
        'script[src="https://www.tiktok.com/embed.js"]'
      );
      existingScripts.forEach((existingScript) => existingScript.remove());

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.tiktok.com/embed.js';
      document.body.appendChild(script);

      setTimeout(() => {
        setIsLoaded(true);
      }, 800);
    }
  }, [html, api]);

  return (
    <div
      {...swipeHandlers} // Attach swipe handlers to the main div
      className={cx('fixed inset-0 z-10 flex items-center justify-center', {
        hidden: !open
      })}
      data-testid="embedded-post"
    >
      {/* Background overlay */}
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        className="absolute inset-0 z-10 bg-[#00000072]"
        onClick={close} // Only close when not swiping
        role="button"
        tabIndex={0}
      ></div>

      {/* Embedded post container */}
      <div
        ref={embedRef}
        data-testid="embedded-post-wrapper"
        className={cx(
          `embedded-post embedded-${api?.toLowerCase()}-post z-20`,
          {
            invisible: !isLoaded,
            visible: isLoaded
          }
        )}
      ></div>
      {!isLoaded && api === 'Instagram' && (
        <div className="absolute z-30 m-auto flex h-[600px] w-80 flex-col justify-between rounded-sm bg-white">
          <div className="flex p-[10px]">
            <div className="gradient h-10 w-10 rounded-full"></div>
            <div className="ml-4 flex flex-col justify-around">
              <div className="gradient mb-1 h-3 w-24 rounded-sm"></div>
              <div className="gradient h-3 w-14 rounded-sm"></div>
            </div>
          </div>
          <div className="gradient gradient w-full flex-grow rounded-sm"></div>
          <div className="p-4">
            <div className="gradient mb-3 h-3 w-52 rounded-sm"></div>
            <div className="gradient h-3 w-36 rounded-sm"></div>
          </div>
        </div>
      )}
      {!isLoaded && api === 'TikTok' && (
        <div className="absolute z-30 m-auto flex h-[739px] w-[325px] max-w-sm flex-col overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="gradient h-full w-full" />
          <div className="h-11" />
          <div className="flex w-full flex-col flex-nowrap content-start px-3 pb-[14px]">
            <div className="gradient mt-3 h-8 w-32" />
            <div className="gradient mt-2 h-10 w-full" />
            <div className="gradient mt-2 h-5 w-40"></div>
          </div>
        </div>
      )}
    </div>
  );
}
